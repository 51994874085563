import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const OutlaunchTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#FC7928',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#A0203E',
    },
  },
  typography: {
    fontFamily: 'Metropolis, Arial',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          {
            fontFamily: 'Metropolis',
            fontStyle: 'normal',
            fontWeight: 300,
            src: `
              local('Metropolis-Light'),
              url('/fonts/Metropolis/Metropolis-Light.otf') format('opentype')
            `,
          },
          {
            fontFamily: 'Metropolis',
            fontStyle: 'normal',
            fontWeight: 400,
            src: `
              local('Metropolis'),
              local('Metropolis-Regular'),
              url('/fonts/Metropolis/Metropolis-Regular.otf') format('opentype')
            `,
          },
          {
            fontFamily: 'Metropolis',
            fontStyle: 'normal',
            fontWeight: 500,
            src: `
              local('Metropolis-Medium'),
              url('/fonts/Metropolis/Metropolis-Medium.otf') format('opentype')
            `,
          },
          {
            fontFamily: 'Metropolis',
            fontStyle: 'normal',
            fontWeight: 700,
            src: `
              local('Metropolis-Bold'),
              url('/fonts/Metropolis/Metropolis-Bold.otf') format('opentype')
            `,
          },
        ],
      },
    },
  },
});

export default responsiveFontSizes(OutlaunchTheme);
