import React from 'react';
import { BoxProps, Box } from '@material-ui/core';

export interface StickyProps {
  top?: number;
  bottom?: number;
}

const Sticky: React.FC<BoxProps & StickyProps> = ({ top, bottom, style, ...props }) => {
  return (
    <Box style={{ ...style, position: 'sticky', top, bottom }} {...props}>
      {props.children}
    </Box>
  );
};

export default Sticky;
