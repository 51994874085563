export const environment = {
  firebase: {
    apiKey: "AIzaSyB4artMVyFCQezKLZyaSQwlAGVwhDSDpPA",
    authDomain: "outlaunch.firebaseapp.com",
    databaseURL: "https://outlaunch.firebaseio.com",
    projectId: "outlaunch",
    storageBucket: "outlaunch.appspot.com",
    messagingSenderId: "726127932074",
    appId: "1:726127932074:web:ab4d8f6491ecbe85a9d6d3",
    measurementId: "G-G3889NEB7P"
  }
};