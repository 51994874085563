import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';

// Redux
import { Provider } from 'react-redux';
import { store } from './store';

// Firebase
import { environment } from './environment';
import { initializeApp } from 'firebase/app';
import 'firebase/firestore';

const app = initializeApp(environment.firebase);
app.firestore().enablePersistence();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
