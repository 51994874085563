import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router';

export interface PageRouteProps extends RouteProps {
  title: string;
  favicon?: string;
}

const PageRoute: React.FC<PageRouteProps> = ({ title, favicon, ...props }) => {
  useEffect(() => {
    const faviconElement = document.getElementById('favicon');
    faviconElement?.setAttribute('href', favicon ?? 'favicon.ico');
  }, [favicon]);
  useEffect(() => {
    document.title = title;
  }, [title]);

  return <Route {...props} />;
};

export default PageRoute;
