import React, { lazy, Suspense } from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';
import PageRoute from 'common/components/PageRoute';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import OutlaunchTheme from './theme/theme';
import { Dashboard } from 'modules/Dashboard/Dashboard';

const PublicSite = lazy(() => import('../modules/PublicSite/PublicSite'));

const Onboarding = lazy(() => import('../modules/Onboarding/Onboarding'));

const App: React.FC = () => {
  return (
    <ThemeProvider theme={OutlaunchTheme}>
      <CssBaseline />
      <BrowserRouter>
        <Suspense fallback={<div />}>
          <Switch>
            <PageRoute path="/dashboard" title="Outlaunch | Dashboard" component={Dashboard} />
            <PageRoute path="/welcome" title="Outlaunch | Welcome" component={Onboarding} />
            <PageRoute path="/" title="Outlaunch" component={PublicSite} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
