import React from 'react';
import { Box, Grid, Typography, Avatar, Button } from '@material-ui/core';
import { Dashboard as DashboardIcon, PermPhoneMsg, Lock, Today, Work, Forum } from '@material-ui/icons';
import Sticky from 'common/components/Sticky';
import { ButtonLink } from 'common/components/Link';

export const Dashboard: React.FC = () => {
  return (
    <>
      <Sticky top={0}>
        <Box bgcolor="white" borderBottom="1px solid rgba(0,0,0,0.05)" px={2} py={1}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <ButtonLink to="/">
              <img alt="wordmark" src="assets/Wordmark_Full.svg" style={{ height: 36 }} />
            </ButtonLink>
            <Button size="small" color="secondary" style={{ borderRadius: 0, padding: '8px 12px' }}>
              Sign out
            </Button>
          </Grid>
        </Box>
      </Sticky>

      <Box bgcolor="white" display="grid" gridTemplateColumns="auto 1fr" height={`calc(100vh - 66px)`}>
        <Box display="flex" flexDirection="column" alignItems="start" justifyContent="space-between" px={3} py={1} borderRight="1px solid rgba(0,0,0,0.05)">
          <Box display="flex" flexDirection="column" alignItems="start" justifyContent="start">
            <Box my={1}>
              <Button startIcon={<DashboardIcon />}>
                <Typography>Dashboard</Typography>
              </Button>
            </Box>

            <Box my={1}>
              <Button startIcon={<Work />}>
                <Typography>Projects</Typography>
              </Button>
            </Box>

            <Box my={1}>
              <Button startIcon={<Today />}>
                <Typography>Calendar</Typography>
              </Button>
            </Box>

            <Box my={1}>
              <Button startIcon={<Forum />} color="primary">
                <Typography>Team Chat</Typography>
              </Button>
            </Box>

            <Box my={1}>
              <Button startIcon={<PermPhoneMsg />}>
                <Typography>Meetings</Typography>
              </Button>
            </Box>

            <Box my={1}>
              <Button startIcon={<Lock />} disabled>
                <Typography>Files</Typography>
              </Button>
            </Box>
          </Box>

          <Button>
            <Box display="flex" alignItems="center">
              <Box mr={2}>
                <Avatar alt="Outlaunch User" src="favicon-96x96.png" style={{ width: 32, height: 32 }} />
              </Box>
              <Box display="flex" flexDirection="column" alignItems="start">
                <Typography variant="body1">Riyaz Khair</Typography>
                <Typography variant="caption">riyaz.khair@outlaunch.co</Typography>
              </Box>
            </Box>
          </Button>
        </Box>

        <Box bgcolor="white" style={{ overflow: 'hidden', height: '100%' }}>
          <img style={{ width: '100%' }} src="assets/Dashboard.png" />
        </Box>
      </Box>
    </>
  );
};
